html,body {
  width: 100%;
  height: 100%;
overflow-y: hidden;
    overflow-x: hidden;




}

.containerFluid{
  height: 100%;

}
button:focus {
    outline: none;
}


.no-gutter {
    margin-right: 0;
    margin-left: 0;
}

.no-gutter > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}
.bg1 {
    /* The image used */
    background-image: url("./GettyImages-587513486_Touch-App-HG-Dunkler.jpg");

    /* Full height */
    height: 100%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg2{
    /* The image used */
    background-image: url("./Symbole_rechts.png");




    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

 .box :hover{
    background-color:#005578 ;

 }
.box{
background-color: #3d87ab ;
}
.boxOpencabinet{
    background-color: #3d87ab ;
    height: 60px;
    cursor:pointer;
    border: none;
    position: absolute;
    top: 50%;
}
.boxCloseCabinet{
    height: 60px;
    cursor:pointer;
    border: none;
    position: absolute;
    top: 50%;
    background-color: black;
    border: white solid 1px;
}


.boxOpencabinet :hover{
    background-color:#005578 ;

}
.configbox {
    cursor:pointer;
    height: 60px;
    width: 25%;



}
.configbox :hover{
    background-color: #ffa500;

}
.configbox1 {
    height: 80px;
    cursor: pointer;
    width: 45%;
    border: none;

}
.configbox2{
    background-color: black;
    cursor:pointer;
    height: 60px;
    width: 25%;
    border: white solid 1px;
}


 .smallBox{

     content: '';
}

#tooltipBox1 {
    position: absolute ;
    bottom: 40%;
    left: 9%;
    height: 58px;
    width:60px;
    content: '';
    cursor: pointer;
}
#tooltipBox2 {
    position: absolute ;
    bottom: 40%;
    left: 28%;
    height: 58px;
    width:60px;
    content: '';
    cursor: pointer;
}
#tooltipBox3 {
    position: absolute ;
    bottom: 40%;
    left:49%;
    height: 58px;
    width:60px;
    content: '';
    cursor: pointer;
}
.subtitle{
    font-size: 14px ;
}
.arrow{
    height: 1.2em;
    width:1.2em ;
}
  .arrowContainer {
      width:10px;
      height:10px;
      position: absolute;
      right: 13px;
      bottom: 16px;

  }
.homePageMenu {
    position: absolute;
    left: 0 ;
    bottom: 18%;
    right: 0;
    height:15%;
}
.menuItemFont{
    font-size: 120%;
}
.sideMenuContainer {
 position:absolute;
    bottom:80px ;
    right:0px;
    width:10% ;
    height:400px;
}
.menuItem{
  background-color:rgba(0, 0, 0, 0.13);
}
.sideMenu :hover {
    background-color: rgba(105, 105, 105, 0.47);

}
.Homebox {
    height: 30%;
    margin-top: 14%;
    position: absolute;
    right: 0px;
    left: 0px;
    bottom:30%
}



.HomeBoxHeading{
    font-size:50px;
    line-height:110%;
    margin-bottom: 10%;

    margin-top:10px;
}
.HomeBoxText{
    font-size:15px;
}
.cabConfig{
    max-height: 400px ;
}
.cabConfig4{
    max-height: 600px
}
.Ultramat6Grafik{
    height: 600px;
}
.Ultramat23Grafik {
    height: 600px ;
}
.Chemicalicon {
    width: 10% ;
    font-size: 10px;
}

.paddingBox {
    padding-top:7%;

}
.buttonActive {
    background-color:#cf9f11;
}
.buttonActive1 {
    background-color: #ffc107;
}
.icon1 {
   fill: #cf9f11;
}
.icon {
    fill: #ffc107;
}
@media screen and (min-width: 1919px){
    .Homebox {
        height: 30%;
        margin-top: 14%;
        position: absolute;
        right: 0px;
        left: 0px;
        bottom:35%
    }
    .HomeBoxHeading{
        font-size:50px;
        line-height:110%;
        margin-bottom: 5%;
        margin-top:10px;
    }

}


@media screen and (max-width: 1919px){
    .sideMenuContainer {
        position:absolute;
        bottom:40px ;
        right:0px;
        width:10% ;
        height:400px;
    }


    .paddingBox {
        padding-top:0%;
    }

    .Chemicalicon{
        width: 10% ;
        font-size:7px;
    }
    .Ultramat6Grafik{
        height: 450px;
    }
    .Ultramat23Grafik {
        height: 450px ;
    }
    .cabConfig{
        max-height: 280px;

    }
    .cabConfig4{
        max-height: 430px
    }
    .homePageMenu {
        position: absolute;
        left: 0 ;
        bottom: 10%;
        right: 0;
        height:25%;
    }
    .menuItemFont{
        font-size: 90%;
    }
    .menuItem{
        background-color: rgba(0, 0, 0, 0.42);
    }
    .configbox{
        height: 50px;
        width: 30%;
    }

    .configbox1{
        height:60px;
        width: 50%;

    }
    .icon :hover {
        fill: #f0ad4e;
    }
    .boxOpencabinet{
        background-color: #3d87ab ;
        height: 50px;
        cursor:pointer;
        position: absolute;
        top: 50%;
        border: none;
    }
    .boxCloseCabinet{
        height: 50px;
        cursor:pointer;
        position: absolute;
        top: 50%;
        border: none;
        background-color: black;
        border: white solid 1px;

    }
    .boxOpencabinet :hover{
        background-color:#005578 ;

    }


    .configbox2{
        height: 50px;
        width: 30%;
        background-color: black;
        border: white solid 1px;

    }


    #tooltipBox1 {

        position: absolute ;
        bottom: 40%;
        left: 9%;
        height: 37px;
        width:42px;
        content: '';
        cursor: pointer;
    }
    #tooltipBox2 {

        position: absolute ;
        bottom: 40%;
        left: 28%;
        height: 37px;
        width:42px;
        content: '';
        cursor: pointer;
    }
    #tooltipBox3 {

        position: absolute ;
        bottom: 40%;
        left: 49%;
        height: 37px;
        width:42px;
        content: '';
        cursor: pointer;
    }
    .subtitle{
        font-size: 12px;
        line-height: 100%;
    }
    .arrow{
        height: 1em;
        width:1em ;
    }
    .arrowContainer {
        width:10px;
        height:10px;
        position: absolute;
        right: 8px;
        bottom: 14px;

    }
    .Homebox{
        height: 45%;
        margin-top:45px;

    }


    .HomeBoxHeading{
        font-size:30px;
        line-height:110%;
        margin-bottom:15%;
        margin-top:10px;
    }
    .HomeBoxText{
        font-size:12px;
    }

}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

